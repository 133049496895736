import { connect } from 'react-redux';
import React, { Component } from 'react';

import NumberFormat from '../../components/NumberFormat';
import { changeCoin } from '../../store/actions/Trading';

class Coins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ETHPairs: {},
      BTCPairs: {},
      USDTPairs: {},
      EURPairs: {},
      searchCoin: '',
      selectedCoin: '',
      selectCoin: 'BTC',
      filteredArr: [],
    }
  }

  componentDidMount() {
    console.log("*********allCoins Mount::", this.props.allCoins);
  }

  componentWillReceiveProps({ allCoins }) {
    console.log("*********allCoins::", allCoins);
    if(!this.state.searchCoin)
    this.setState({ ETHPairs: allCoins['ETH'], BTCPairs: allCoins['BTC'], USDTPairs: allCoins['USDT'], EURPairs: allCoins['EUR'] });
  }

  selectCoin = (coin) => console.log(`********Change Coin = `, coin);

  handleSearchCoin = (e) => {
    let searchCoin = e.target.value.toLowerCase();
    this.setState({ searchCoin });

    let { allCoins } = this.props;
    let {selectCoin} = this.state;

    if (allCoins) {
      let defaultCoins = allCoins[selectCoin];
      let coinArray = Object.values(defaultCoins);

      let filteredArr = coinArray.filter(({ symbol }) => symbol.toLowerCase().indexOf(searchCoin) > -1);

      let newObj = {};
      filteredArr.forEach(coin=> newObj[coin['symbol']] = coin);

      this.setState({ [`${selectCoin}Pairs`]: newObj });
    }
  }

  render() {
    let { changeCoin } = this.props;
    let { ETHPairs, USDTPairs, BTCPairs, EURPairs } = this.state;
    return (
      <>
        <div className="left-side-bar smooth-scroll list-unstyled">
          <div className="scrol-click"><a href="#"><img src={require("../../static/images/icons/6.png")} alt="Softtik Exchange" /></a></div>
          <div className="search-box">
            <form method="post">
              <div className="form-group">
                {/* <button type="submit" onClick={this.handleSearchCoin}><span className="icon fa fa-search"></span></button> */}
                <input type="search" name="search-field" placeholder="Search coin" onChange={this.handleSearchCoin} />
              </div>
            </form>
          </div>
          <div className="tabs-table">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="BTC-tab" data-toggle="tab" href="#BTC" role="tab" aria-controls="BTC" aria-selected="true" onClick={()=> this.setState({selectCoin: "BTC"})}>BTC</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="EUR-tab" data-toggle="tab" href="#EUR" role="tab" aria-controls="EUR" aria-selected="false" onClick={()=> this.setState({selectCoin: "EUR"})}> EUR</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="USDT-tab" data-toggle="tab" href="#USDT" role="tab" aria-controls="USDT" aria-selected="false" onClick={()=> this.setState({selectCoin: "USDT"})}>USDT</a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="BTC" role="tabpanel" aria-labelledby="BTC-tab">
                <table className="table table-striped table-responsive-sm table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Pairs</th>
                      <th scope="col">Price</th>
                      <th scope="col">Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(BTCPairs).map(coin => {
                      let data = BTCPairs[coin];
                      return (
                        <tr style={{ cursor: 'pointer' }} onClick={() => changeCoin(coin)}>
                          <th scope="row">{coin}</th>
                          <td><NumberFormat value= {data['price']} coin= {coin.split('/')[1]}/></td>
                          <td className={data['change'] > 0 ? "text-success" : "red"}>{data['change']}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="tab-pane fade" id="EUR" role="tabpanel" aria-labelledby="EUR-tab">
                <table className="table table-striped table-responsive-sm table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Pairs</th>
                      <th scope="col">Price</th>
                      <th scope="col">Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(EURPairs).map(coin => {
                      let data = EURPairs[coin];
                      return (
                        <tr style={{ cursor: 'pointer' }} onClick={() => changeCoin(coin)}>
                          <th scope="row">{coin}</th>
                          <td><NumberFormat value= {data['price']} coin= {coin.split('/')[1]}/></td>
                          <td className={data['change'] > 0 ? "text-success" : "red"}>{data['change']}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="tab-pane fade" id="USDT" role="tabpanel" aria-labelledby="USDT-tab">
                <table className="table table-usdt table-striped table-responsive-sm table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Pairs</th>
                      <th scope="col">Price</th>
                      <th scope="col">Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(USDTPairs).map(coin => {
                      let data = USDTPairs[coin];
                      return (
                        <tr style={{ cursor: 'pointer' }} onClick={() => changeCoin(coin)}>
                          <th scope="row">{coin}</th>
                          <td><NumberFormat value= {data['price']} coin= {coin.split('/')[1]}/></td>
                          <td className={data['change'] > 0 ? "text-success" : "red"}>{data['change']}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { changeCoin };
const mapStateToProps = ({ Trading }) => {
  let { allCoins, selectedCoin } = Trading;
  return { allCoins, selectedCoin };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coins);