import jwt_decode from 'jwt-decode';
import { setToken } from '../axios';
import { updateID } from '../sagas/Sockets';

let initialState = {
  auth: localStorage.getItem('token'),
  kycData: '',
  isKYCModal: false,
  userAuth: {},
  googleAuth: {},
  loading: false,
  lightMode: false,
  userDetails: {},
  loginHistory: [],
  isUserAuthModal: false,
  isModalAntiPhishing: false,
  landingBannerData: {},
  landingTableData: {},
  apiKeyLoader: false,
  apiKeys: [],
  allReferrals: [],
  referralCode: '',
  whitelistedAddress: [],
  isWhitelistAdded: false,
};
// var(--orange-light-light)

const Auth = (state = initialState, { type, payload }) => {

  switch (type) {

    case "USER_DATA":
      let id = (jwt_decode(payload['token']))['_id'];
      localStorage.setItem('token', payload['token']);

      updateID(id);
      setToken(payload['token']);
      return {
        ...state,
        auth: payload['token']
      };

    case "USER_AUTH":
      return {
        ...state,
        userAuth: payload,
      };

    case "SET_KYC_DATA":
      return {
        ...state,
        kycData: payload,
      };

    case "TOGGLE_AUTH_MODAL":
      return {
        ...state,
        isUserAuthModal: !state['isUserAuthModal'],
      };

    case 'GOOGLE_AUTH_SETTINGS':
      return {
        ...state,
        googleAuth: payload
      };

    case 'LOGOUT':
      setToken();
      localStorage.removeItem('token');
      return {
        ...state,
        auth: ''
      };

    case "CHANGE_MODE":
      return {
        ...state,
        lightMode: payload
      };

    case 'LOADING':
      return {
        ...state,
        loading: payload
      };

    case 'MY_PAGE_DATA':
      return {
        ...state,
        userDetails: payload ? payload['user'] : {},
        loginHistory: payload ? payload['logging'] : [],
      };

    case 'MY_PAGE_USER_DATA':
      return {
        ...state,
        userDetails: payload ? payload : {},
      };

    case "TOGGLE_MODAL_PHISHING":
      return {
        ...state,
        isModalAntiPhishing: !state['isModalAntiPhishing'],
      };

    case "LANDING_PAGE_DATA":
      return {
        ...state,
        landingBannerData: payload['bannerCoin'],
        landingTableData: payload['tableCoins']
      };

    case "TOGGLE_KYC_MODAL":
      return {
        ...state,
        isKYCModal: !state['isKYCModal'],
      };

    case "API_KEYS_LOADER":
      return {
        ...state,
        apiKeyLoader: payload,
      };

    case "SET_API_KEYS":
      return {
        ...state,
        apiKeys: payload,
      };

    case "SET_REFERRALS":
      return {
        ...state,
        allReferrals: payload['allReferals'],
        referralCode: payload['refferalCode'],
      };

    case "SET_WHITELISTED_ADDRESS":
      return {
        ...state,
        // whitelistedAddress: payload,
        whitelistedAddress: [],
      };

    case "WHITELIST_ADDED":
      return {
        ...state,
        isWhitelistAdded: payload,
      };

    default:
      return state;
  }
};

export default Auth;