import io from 'socket.io-client';

/* -- set app title --*/
const AppTitle = 'Softtik Exchange';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'http://3.21.55.164:4000';
const prototype = 'https://sandbox.softtik.com';
const development = 'https://sandbox.softtik.com';
// const development = 'https://dserver.crowdpoint.tech';


let SocketUrl;
switch (AppMode[0]) {
  case 'development':
    SocketUrl = development;
    break;
  case 'prototype':
    SocketUrl = prototype;
    break;
  case 'testing':
    SocketUrl = testing;
    break;
  default:
    SocketUrl = 'http://localhost:4000';
}

let socket = io(SocketUrl);
let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, SocketUrl, socket };
