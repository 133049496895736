import QRCode from "react-qr-code";
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import React, { Component, Fragment } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import "./index.css";
import { APIKeysLoader, createAPIKey, deleteAllAPIKey, deleteAPIKey, editApiKey } from '../../store/actions/Auth';

class AllKeys extends Component {

  constructor(props) {
    super(props);
    this.state = {
      key: {
        label: '',
      },
      isCheckbox: '-',
      apiKeysArray: [],
      restrictIPs: '',
    };
  }

  componentDidMount() {
    this.setState({ apiKeysArray: this.props['apiKeys'] })
    console.log("*********apiKeys::", this.props['apiKeys']);
  }

  handleChange = (e) => {
    const { key } = this.state;
    key[e.target.name] = e.target.value;
    this.setState({ key });
  };

  handleAPIKey = () => {
    this.props.createAPIKey({ data: this.state.key });
    this.props.APIKeysLoader(true);
    setTimeout(() => {
      const { key } = this.state;
      key['label'] = '';
      this.setState({ key });
    }, 1000);
  };

  handleDeleteKey = (id) => {
    this.props.APIKeysLoader(true);
    this.props.deleteAPIKey({ data: id });
  }

  handleDeleteAllKey = () => {
    this.props.APIKeysLoader(true);
    this.props.deleteAllAPIKey();
  }

  handleEdit = (i) => this.setState({ isCheckbox: i })

  copied = () => EventBus.publish("success", 'Copied to Clipboard');

  editKey = (name, i) => {
    let { apiKeysArray } = this.state;
    apiKeysArray[i][name] = !apiKeysArray[i][name];
    this.setState({ apiKeysArray });
  };

  editIPRestriction = (i, e) => {
    let { apiKeysArray } = this.state;
    apiKeysArray[i]['ipRestriction'] = e.target.value;
    this.setState({ apiKeysArray });
  }

  saveEdit = (index) => {
    let updatedKeys = this.state.apiKeysArray[index];
    this.props.APIKeysLoader(true);
    this.props.editApiKey(updatedKeys);
    this.setState({ isCheckbox: '-' });
  }

  render() {
    let { key, isCheckbox, apiKeysArray } = this.state;
    return (
      <>
        <section className="api-allkeys">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="api-sec">
                  <div className="api-box">
                    <h2>Create Api</h2>
                    <p>Give the API key a label</p>
                    <form className="api-key-form">
                      <input onChange={this.handleChange} value={key['label']} type="text" name="label" placeholder="Enter The API Key Label " />
                      <button type="button" onClick={this.handleAPIKey}>Create</button>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="api-list">
                  <div className="api-box">
                    <div className="listapi">
                      <h3>API List</h3>
                      <button type="button" onClick={this.handleDeleteAllKey}>Delete All API</button>
                    </div>
                    {apiKeysArray.map((data, i) => {
                      return (
                        <div>
                          <div className="listapi">
                            <h3>{data['label']}</h3>
                            <button type="button" onClick={() => this.handleDeleteKey(data['_id'])}>Delete</button>
                            {
                              isCheckbox === i ?
                                <button type="button" onClick={() => this.saveEdit(i)}>Save Changes</button>
                                :
                                <button type="button" onClick={() => this.handleEdit(i)}>Edit Restrictions</button>
                            }
                          </div>
                          <div className="qr-area">
                            <div className="qr-inner">
                              <div className="qr-box">
                                {data['key'] && <QRCode value={data['key']} size='150' />}
                              </div>
                              <p>Scan to Get Address</p>
                            </div>
                            <div className="right-api-area">
                              <h3>API Key</h3>
                              {data['key']
                                ? <p>{data['key']} <CopyToClipboard text={data['key']} onCopy={this.copied}>
                                  <a>Copy</a>
                                </CopyToClipboard></p>
                                : <p>--</p>
                              }
                              <h3>API Secret <span className="icon-area"><img src={require("../../static/images/icon-1.png")} alt="" /></span></h3>
                              {data['secret']
                                ? <p>{data['secret']} <CopyToClipboard text={data['secret']} onCopy={this.copied}>
                                  <a>Copy</a>
                                </CopyToClipboard></p>
                                : <p>--</p>
                              }
                              <div className="checkbox-area">
                                <label class="lable-checkbox">
                                  <input disabled={!(isCheckbox === i)} checked={data['readInfo']} onClick={() => this.editKey('readInfo', i)} type="checkbox" />
                                  Can Read
                                </label>
                                <label class="lable-checkbox">Enable Spot Trading
                                    <input disabled={!(isCheckbox === i)} checked={data['enableTrading']} onClick={() => this.editKey('enableTrading', i)} type="checkbox" />
                                </label>
                                <label class="lable-checkbox">Enable Withdrawals
                                    <input disabled={!(isCheckbox === i)} checked={data['enableWithdrawal']} onClick={() => this.editKey('enableWithdrawal', i)} type="checkbox" />
                                </label>
                              </div>

                              <div className="clearfix"></div>
                              <div className="ip-area">
                                <h3>IP access restrictions:</h3>
                                <FormControl component='fieldset'>
                                  <RadioGroup className='radio-group' value={data['restrict']} onChange={() => this.editKey('restrict', i)}>
                                    <FormControlLabel
                                      value={false}
                                      disabled={!(isCheckbox === i)}
                                      name="restrict"
                                      control={<Radio />}
                                      label={<label className='radio-group-label'>unrestricted (Less Secure) <i>This API key allows restrict from any IP address. This is not recommended.</i></label>} />
                                    <FormControlLabel
                                      value={true}
                                      disabled={!(isCheckbox === i)}
                                      name="restrict"
                                      control={<Radio />}
                                      label={<label className='radio-group-label'>Restrict access to trusted IPs only (Recommended)</label>} />
                                  </RadioGroup>
                                </FormControl>
                                {data['restrict'] &&
                                  <div className="ip-restrict-field">

                                    <input disabled={!(isCheckbox === i)} value={data['ipRestriction']} onChange={(e) => this.editIPRestriction(i, e)} type="text" name="ipRestriction" placeholder="Enter The IPs" />
                                    <p>Please seperate the IPs by Comma(,) </p>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          <hr style={{ borderTop: '1px solid rgba(255,255,255,0.3)' }} />
                        </div>
                      );
                    })}

                  </div>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}


const mapDispatchToProps = { APIKeysLoader, createAPIKey, deleteAllAPIKey, deleteAPIKey, editApiKey };
const mapStateToProps = ({ Auth }) => {
  let { lightMode, apiKeys } = Auth;
  return { lightMode, apiKeys };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllKeys);
