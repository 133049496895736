import moment from "moment";
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import Navbar from '../../components/Navbar';
import { getReferrals } from '../../store/actions/Auth';

class Refferal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            allReferralsArr: props.allReferrals,
        };
        props.getReferrals();
    };

    componentWillReceiveProps({ allReferrals }) {
        this.setState({ allReferralsArr: allReferrals })
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value },
        () => {
            let { search } = this.state;
            let { allReferrals } = this.props;
            if (search !== null) {
                if (allReferrals) {
                    this.setState({ allReferralsArr: allReferrals.filter(({ userId }) => userId['name'].toLowerCase().indexOf(search) >= 0) })
                }
            }
        }
    );

    copied = () => EventBus.publish("success", 'Code Copied');


    render() {
        let { search, allReferralsArr } = this.state;
        let { lightMode, referralCode } = this.props;
        console.log("************allReferralsArr::", this.state.allReferralsArr);
        return (
            <div className={`page-wrapper-inner ${lightMode ? 'light' : 'dark'}`}>
                <Navbar {...this.props} />
                <section className="referral">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="whitelisting-form ">
                                    <div className="dash-top-title">
                                        <div className='row'>
                                            <div className='col-1 back-btn'>
                                                <Link to='/myPage'><i class="fa fa-arrow-left text-white mt-2" aria-hidden="true"></i></Link>
                                            </div>
                                            <div className='col-11 title d-flex justify-content-center'>
                                                <h2>Referral System</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="whitelisting-address-manage">
                                        <div className='row'>
                                            <div className="col-lg-2 col-md-2 col-12 left-heading-white ">
                                                <h4 className='pt-4'>Referral Code </h4>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12">
                                                <div className="second-drop">
                                                    <div className="textbox-white">
                                                        <div className="textbox">
                                                            <input disabled={true} value={referralCode} name='referralCode' type="text"></input>
                                                            <FormHelperText id="component-helper-text" style={{fontSize: '10px'}}>You can earn by sharing this referral code to others</FormHelperText>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-12">
                                                <CopyToClipboard text={referralCode} onCopy={this.copied}>
                                                    <button className="btn-style-threref">Copy Code</button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="whitelisting-form mt-5">
                                    <div className="whitelisting-address-manage">
                                        <div className="row">
                                            <div className="col-md-6 left-heading-white">
                                                <h4>Users Added by Referral</h4>
                                            </div>
                                            <div className="offset-2 col-lg-4 col-md-4 form-search ml-auto">
                                                <div class="buttonInnnss ">
                                                    <input onChange={this.handleChange} value={search} name="search" type="text" className="input-button" placeholder="Search Name" id="enter" />
                                                    <button id="clear"><i class="fas fa-search" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="overflow-x:auto">
                                                    <div class="table-responsive referral-table pt-3">
                                                        <table class="table table-clr table-striped text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Account Type</th>
                                                                    <th scope="col">Added On</th>
                                                                    <th scope="col">Earnings</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {allReferralsArr.length > 0
                                                                    ? allReferralsArr.map(data => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{data['userId'] && data['userId']['email'] ? data['userId']['email'] : '--'}</td>
                                                                                <td>{data['userId'] && data['userId']['name'] ? data['userId']['name'] : '--'}</td>
                                                                                <td>{data['userId'] && data['userId']['account'] ? data['userId']['account'] : '--'}</td>
                                                                                <td>{data['lastUpdated'] ? moment(data["lastUpdated"]).format("lll") : '--'}</td>
                                                                                <td>{data['earning'] && data['earning']['USD'] ? `$ ${data['earning']['USD']}` : '--'}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                    : <tr>
                                                                        <td colSpan={7}>
                                                                            <p className='my-5'>No Referrals Code</p>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}


const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#000 !important', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const mapDispatchToProps = { getReferrals };
const mapStateToProps = ({ Auth, }) => {
    let { lightMode, allReferrals, referralCode } = Auth;
    return { lightMode, allReferrals, referralCode };
};

export default connect(mapStateToProps, mapDispatchToProps)(Refferal);