import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import AppLoader from '../../../components/AppLoader';
import NumberFormat from '../../../components/NumberFormat';
import { getDepositDetails, getDepositFiatHistory } from '../../../store/actions/Wallet';


class Fiat extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    props.getDepositDetails();
    props.getDepositFiatHistory();
  }


  render() {
    let { depositDetails, depositFiatHistory, isHistoryLoading } = this.props;
    return (
      <>
        <div class="row clearfix">
          <div class="deposit-fiat col-lg-6 col-md-12">
            <div className="fiat-main">
              <div className="card-header" role="tab" id="headingOne1">
                <div className="heading-title">
                  <AccountBalanceIcon />
                  <h4 className="ml-3">Deposit Details</h4>
                </div>
              </div>
              <div className="card-body mt-4">
                {Object.keys(depositDetails).length !== 0
                  ?
                  <div className="deposit-details">
                    <table>
                      <tr>
                        <td className='pr-3 pt-3'> <strong>Beneficiary:</strong> </td>
                        <td className='pt-3'> {depositDetails['clientName']} </td>
                      </tr>
                      <tr>
                        <td className='pr-3 pt-3'> <strong>Account Number:</strong> </td>
                        <td className='pt-3'> {depositDetails['account']} </td>
                      </tr>
                      <tr>
                        <td className='pr-3 pt-3'> <strong>Bank:</strong> </td>
                        <td className='pt-3'> {depositDetails['bankName']} </td>
                      </tr>
                      <tr>
                        <td className='pr-3 pt-3'> <strong>Swift Code:</strong> </td>
                        <td className='pt-3'> {depositDetails['bicCode']} </td>
                      </tr>
                      <tr>
                        <td className='pr-3 pt-3'> <strong>Bank Address:</strong> </td>
                        <td className='pt-3'> {depositDetails['clientName']} </td>
                      </tr>
                      <tr>
                        <td className='pr-3 pt-3'> <strong>Payment Details:</strong> </td>
                        <td className='pt-3 highlighted'> {depositDetails['paymentId']} </td> {/* Orange */}
                      </tr>
                      <tr>
                        <td className='pr-3 pt-3'> <strong>Deposit Commision:</strong> </td>
                        <td className='pt-3'> {depositDetails['depositComission']} EUR </td>
                      </tr>
                      <tr>
                        <td className='pr-3 pt-3'> <strong>Minimum Deposit Amount:</strong> </td>
                        <td className='pt-3'> {depositDetails['minimumDeposit']} EUR </td>
                      </tr>
                    </table>
                  </div>
                  : <AppLoader />
                }
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="history-deposit">
              <h5>History</h5>
              <div class="table-responsive-md">
                <table class="table table-clr text-center">
                  <thead>
                    <tr>
                      <th scope="col">Time <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Coin <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Name <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Amount <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Status <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isHistoryLoading
                      ? <tr>
                        <td colSpan={7}>
                          <AppLoader message={"Loading Deposit Fiat history"} />
                        </td>
                      </tr>
                      : <Fragment>
                        {depositFiatHistory.map(data => {
                          return (
                            <tr>
                              <td>{moment(data['createdAt']).format('MMM Do, h:mm a')}</td>
                              <td>{data['currency']}</td>
                              <td>{data['beneficiaryName']}</td>
                              <td><NumberFormat value={data['amount']} decimals={2} /></td>
                              <td>{data['status']}</td>
                            </tr>
                          )
                        })}
                      </Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { getDepositDetails, getDepositFiatHistory };

const mapStateToProps = ({ Wallet }) => {
  let { depositDetails, depositFiatHistory, isHistoryLoading } = Wallet;
  return { depositDetails, depositFiatHistory, isHistoryLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fiat);