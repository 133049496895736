import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import MuiPhoneInput from 'material-ui-phone-number';
import { withStyles } from '@material-ui/core/styles';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import MainNavbar from '../../components/MainNavbar';
import { signupAdvance, load } from '../../store/actions/Auth';

import './index.css';
import countries from './country.json'

class SignupAdvance extends Component {
  constructor() {
    super();
    const query = require('url').parse(document.location.href, true);
    const hash = query.path.split('/')[--query.path.split('/').length];
    this.state = {
      formData: {
        hash,
        dob: new Date('1995-09-18T21:11:54'),
        phone: '',
        country: '',
        address: '',
      },
      countryCode: 'us',
    };
  }

  submitCompleteSignup = () => {
    this.props.load(true);
    this.props.signupAdvance({ data: this.state.formData, history: this.props.history })
  }

  handleFormChange = ({ target }) => {
    const { formData } = this.state;
    formData[target.name] = target.value;
    this.setState({ formData });
  }

  setDateOfBirth = (date) => {
    let { formData } = this.state;
    formData['dob'] = date;
    this.setState({ formData });
  }

  setPhoneNumber = (number) => {
    let { formData } = this.state;
    formData['phone'] = number;
    this.setState({ formData });
  }

  countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  selectedCountry = (e, value) => {
    let { formData } = this.state;
    formData['country'] = value['label'];
    this.setState({ formData });
  }

  render() {
    let { loading } = this.props;
    let { dob, phone, address } = this.state.formData;
    let { countryCode } = this.state;

    return (
      <>
        <MainNavbar {...this.props} />
        <section className="signup-page">
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5 mb-3">
                <div className="signup-form">
                  <div className="row clearfix">
                    <div className="col-lg-5 offset-lg-1 1col-md-12 col-sm-12">
                      <div className="form-area">
                        <ValidatorForm className="validatorForm mt-5" onSubmit={this.submitCompleteSignup}>
                          <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields" item xs={12}>
                              <EventNoteIcon className='input-icon mt-4 mr-2' />
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  fullWidth
                                  className='MyTextField'
                                  name="dob"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date of Birth"
                                  format="MM/dd/yyyy"
                                  value={dob}
                                  onChange={(date) => this.setDateOfBirth(date)}
                                  KeyboardButtonProps={{ 'aria-label': 'Date of Birth' }}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields" item xs={12}>
                              <PhoneIcon className='input-icon mt-4 mr-2' />
                              <MuiPhoneInput
                                fullWidth
                                label='Phone Number'
                                name="phone"
                                value={phone}
                                margin="dense"
                                autoFormat={false}
                                defaultCountry={countryCode}
                                id='standard-full-width'
                                className='MyTextField'
                                onChange={(number) => this.setPhoneNumber(number)}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields" item xs={12}>
                              <PublicIcon className='input-icon mt-4 mr-2' />
                              <Autocomplete
                                fullWidth
                                id="country-select-demo"
                                className="autocomplete-field"
                                options={countries}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => this.selectedCountry(event, newValue)}
                                onClick
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <span>{this.countryToFlag(option.code)}</span>
                                    &nbsp; {option.label} ({option.code})
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    label='Country'
                                    className='MyTextField'
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields" item xs={12}>
                              <HomeIcon className='input-icon mt-4 mr-2' />
                              <CustomTextField
                                fullWidth
                                className="MyTextField"
                                autoComplete='off'
                                label="Address"
                                name="address"
                                type="address"
                                value={address}
                                margin="dense"
                                onChange={this.handleFormChange}
                                validators={['required']}
                                errorMessages={['Address can not be empty']}
                              />
                            </Grid>
                          </Grid>

                          <div className="submit-box">
                            <Button
                              type="submit"
                              disabled={loading}
                            >
                              {!loading ? 'SUBMIT' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                            </Button>
                          </div>
                          <p></p>
                        </ValidatorForm>
                        <div className="already-memmber mb-5">
                          <p>Already a member?</p>
                          <Link to='/login' className="login-btn">Login Now</Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="image-column">
                        <figure><img src={require("../../static/images/resource/Signup.png")} alt="" /></figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fa6634', // Solid underline on focus
    },
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    }
  }
})(TextValidator);

const mapDispatchToProps = { signupAdvance, load };

const mapStateToProps = ({ Auth }) => {
  let { loading } = Auth;
  return { loading };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupAdvance);