import moment from 'moment';
import { connect } from 'react-redux';
import Note from '@material-ui/icons/Note';
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import EuroSymbol from '@material-ui/icons/EuroSymbol';
import AddLocation from '@material-ui/icons/AddLocation';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import AppLoader from '../../../components/AppLoader';
import NumberFormat from '../../../components/NumberFormat';
import { withdrawEURLoader, withdrawEUR, getWithdrawFiatHistory } from '../../../store/actions/Wallet';


class Fiat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        beneficiaryName: '',
        beneficiaryAccount: '',
        beneficiaryAddress: '',
        beneficiaryReference: '',
        amount: '',
      }
    };
    props.getWithdrawFiatHistory();
  }

  handleWithdrawEUR = () => {
    this.props.withdrawEURLoader(true)
    this.props.withdrawEUR(this.state.formData);
    this.setState({ beneficiaryName: '', beneficiaryAccount: '', beneficiaryAddress: '', beneficiaryReference: '', amount: '' })
  }

  handleChange = (e) => {
    const { formData } = this.state;
    formData[e.target.name] = e.target.value;
    this.setState({ formData });
  }


  render() {
    let { formData } = this.state;
    let { isWithdrawEUR, withdrawFiatHistory, isHistoryLoading } = this.props;
    return (
      <>
        <div class="row clearfix">
          <div class="deposit-fiat col-lg-6 col-md-12">
            <div className="fiat-main">
              <div className="card-header" role="tab" id="headingOne1">
                <div className="heading-title">
                  <AccountBalanceIcon />
                  <h4 className="ml-3">Bank SEPA</h4>
                </div>
              </div>
              <div className="card-body">
                {isWithdrawEUR
                  ? <div className="fiat-status my-5">
                    <AppLoader message={"is Proceeding...."} />
                  </div>
                  : <Fragment>
                    <ValidatorForm className="fiat-form" onSubmit={this.handleWithdrawEUR}>
                      <div className="group-form mt-3">
                        <CustomTextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          type='text'
                          name='beneficiaryName'
                          label='Beneficiary name, surname'
                          value={formData['beneficiaryName']}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleChange}
                          // placeholder='Please enter your Name'
                          validators={['required']}
                          errorMessages={['Name is Required']}
                        />
                      </div>
                      <div className="group-form mt-3">
                        <CustomTextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountBalanceWallet />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          type='text'
                          name='beneficiaryAccount'
                          label='Beneficiary account number'
                          value={formData['beneficiaryAccount']}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleChange}
                          // placeholder='Please enter your Account'
                          validators={['required']}
                          errorMessages={['Account is Required']}
                        />
                      </div>
                      <div className="group-form mt-3">
                        <CustomTextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AddLocation />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          type='text'
                          name='beneficiaryAddress'
                          label='Beneficiary address'
                          value={formData['beneficiaryAddress']}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleChange}
                          // placeholder='Please enter your Address'
                          validators={['required']}
                          errorMessages={['Address is Required']}
                        />
                      </div>
                      <div className="group-form mt-3">
                        <CustomTextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Note />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          type='text'
                          name='beneficiaryReference'
                          label='Payment details, reference'
                          value={formData['beneficiaryReference']}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleChange}
                          // placeholder='Please enter your Reference'
                          validators={['required']}
                          errorMessages={['Reference is Required']}
                        />
                      </div>
                      <div className="group-form mt-3">
                        <CustomTextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EuroSymbol />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          type='number'
                          name='amount'
                          label='Payment amount (EUR)'
                          value={formData['amount']}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleChange}
                          // placeholder='Please enter your Amount'
                          validators={['required']}
                          errorMessages={['Amount is Required']}
                        />
                      </div>
                      <div className="btn-form mt-4">
                        <button type='submit' className="theme-btn btn-style-three">Submit</button>
                      </div>
                    </ValidatorForm>
                  </Fragment>
                }
                <div className="bottom-note mt-3">
                  <p>
                    Please make sure to withdraw to bank account by your name.
                    <br/>
                    Minimum Withdraw amount is 5 EUR.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="history-deposit">
              <h5>History</h5>
              <div class="table-responsive-md">
                <table class="table table-clr text-center">
                  <thead>
                    <tr>
                      <th scope="col">Time <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Coin <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Name <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Amount <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Status <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isHistoryLoading
                      ? 
                      <tr>
                        <td colSpan={7}>
                          <AppLoader message={"Loading Withdraw Fiat history"} />
                        </td>
                      </tr>
                      : <Fragment>
                        {withdrawFiatHistory.map(data => {
                          return (
                            <tr>
                              <td>{moment(data['createdAt']).format('MMM Do, h:mm a')}</td>
                              <td>{data['currency']}</td>
                              <td>{data['beneficiaryName']}</td>
                              <td><NumberFormat value={data['amount']} decimals={2} /></td>
                              <td>{data['status']}</td>
                            </tr>
                          )
                        })}
                      </Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fa6634', // Solid underline on focus
    },
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    }
  }
})(TextValidator);

const mapDispatchToProps = { withdrawEURLoader, withdrawEUR, getWithdrawFiatHistory };

const mapStateToProps = ({ Wallet }) => {
  let { isWithdrawEUR, withdrawFiatHistory, isHistoryLoading } = Wallet;
  return { isWithdrawEUR, withdrawFiatHistory, isHistoryLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fiat);