import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import './index.css';
import MainNavbar from '../../components/MainNavbar';
import NumberFormat from '../../components/NumberFormat';
import { verifyWithdrawal } from '../../store/actions/Wallet';

class Landing extends Component {

  componentDidMount() {
    let { location } = this.props;
    if (location.pathname.match('verify_withdrawal')) {
      let data = location.pathname.split('/')[--location.pathname.split('/').length];
      this.props.verifyWithdrawal({ data, history: this.props.history });
    }
  }

  render() {
    let { auth, landingBannerData, landingTableData } = this.props;
    return (
      <div style={{ background: 'white' }}>
        <MainNavbar {...this.props} />
        <section className="banner-section style-two">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="content-box">
                  <h1>The Future of Financing Technology is Here.</h1>
                  <div className="text">Fast and convenient trading experience for professional investors.</div>
                  <div className="link-box">
                    <Link to='/signup' className="theme-btn btn-style-one"> Sign up now </Link>
                    {/* <a href="https://www.cryptoexchangesoftware.net/" target="_blank" className="theme-btn btn-style-one">get in touch</a> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="image-column">
                  <figure><img src={require("../../static/images/main-slider/banner-2.png")} alt="" /></figure>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="coins-box">
                  {
                    Object.values(landingBannerData).map(data => {
                      return (
                        <div className="coin-inner text-center">
                          <div className="icon-area">
                            <figure><img src={require(`../../static/images/icons/${data['symbol']}.png`)} alt="" /></figure>
                          </div>
                          <div className="text-box">
                            <h3>{data['name']} <br /> ({data['symbol']})</h3>
                            <div className="payment"><NumberFormat value={data['close']} decimals={2} /></div>
                            <div className={`status-perc ${data['change'] > 0 ? 'green' : 'red'}`}>
                              <i className={`fa ${data['change'] > 0 ? 'fa-caret-up' : 'fa-caret-down'}`}></i> {data['change']}%
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-choose-us">
          <div className="container">
            <div className="row">
              <div className="service-outer col-lg-4 col-md-6 col-sm-12">
                <div className="service-inner">
                  <div className="title-area">
                    <h2>Buy. Sell. Trade.</h2>
                  </div>
                  <div className="text-box">
                    <div className="icon-area">
                      <figure><img src={require("../../static/images/icons/27.png")} alt="" /></figure>
                    </div>
                    <div className="text">Get in, trade, cash out, repeat. Buy Bitcoin with your credit and debit card from your BitDandy wallet. </div>
                  </div>
                </div>
              </div>
              <div className="service-outer col-lg-4 col-md-6 col-sm-12">
                <div className="service-inner">
                  <div className="title-area">
                    <h2>Exchange</h2>
                  </div>
                  <div className="text-box">
                    <div className="icon-area">
                      <figure><img src={require("../../static/images/icons/28.png")} alt="" /></figure>
                    </div>
                    <div className="text">BitDandy offers order books with top tier liquidity, allowing users to easily exchange Bitcoin, Ethereum, Dashcoin, Litecoin, and Bitcoincash with minimal slippage. </div>
                  </div>
                </div>
              </div>
              <div className="service-outer col-lg-4 col-md-6 col-sm-12">
                <div className="service-inner">
                  <div className="title-area">
                    <h2>Security</h2>
                  </div>
                  <div className="text-box">
                    <div className="icon-area">
                      <figure><img src={require("../../static/images/icons/29.png")} alt="" /></figure>
                    </div>
                    <div className="text">The security of user information and funds is our first priority. Learn more about our security features and integrations.
 </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="account-sec" style={{ backgroundImage: `url(${require("../../static/images/background/1.jpg")})` }}>
          <div className="container">
            <div className="row">
              <div className="content-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-clomun">
                  <div className="title-area">
                    <h3>Trade On the <strong>Cryptocurrency Derivatives</strong> Exchange with the Best Perpetual Contracts</h3>
                  </div>
                  <div className="text">We listen, care, and improve to provide the best possible trading experience</div>
                  <div className="link-box">
                    {!auth && <a href="/signup" className="theme-btn btn-style-one">Create Account with in five second</a>
                    }
                  </div>
                </div>
              </div>
              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <figure><img src={require("../../static/images/resource/macbook.png")} alt="" /></figure>
              </div>
            </div>
          </div>
        </section>

        <section className="table-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>Realtime Cryptocurrency</h2>
                </div>
              </div>
            </div>
            <div className="table-responsive-lg">
              <table className="table table-hover table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col ml-2">Coin Name</th>
                    <th scope="col"><i className="fa fa-caret-up  "></i><i className="fa fa-caret-down"></i><span className="ml-3">Price</span></th>
                    <th scope="col"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i><span className="ml-3">Volume (24hr)</span> </th>
                    <th scope="col"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i><span className="ml-3">High</span> </th>
                    <th scope="col"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i><span className="ml-3">Low</span> </th>
                    <th scope="col"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i><span className="ml-3">Change</span> </th>
                    {/* <th scope="col"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i><span className="ml-3">Actions</span> </th> */}
                  </tr>
                </thead>
                <tbody className="tbody-dark">
                  {Object.values(landingTableData).map(data => {
                    return (
                      <tr>
                        <th scope="row" className="first-ch"><img className='landing-table-img' src={require(`../../static/images/icons/${data['symbol']}.png`)} alt="" />{data['name']}</th>
                        <td className="text-dark">$<NumberFormat value={data['close']} decimals={2} /></td>
                        <td className="text-dark">$<NumberFormat value={data['volume']} decimals={2} /></td>
                        <td className="text-dark">$<NumberFormat value={data['high']} decimals={2} /></td>
                        <td className="text-dark">$<NumberFormat value={data['low']} decimals={2} /></td>
                        <td className={`status-perc ${data['change'] > 0 ? 'green' : 'red'}`}>
                          <i className={`fa ${data['change'] > 0 ? 'fa-caret-up' : 'fa-caret-down'}`}></i> {data['change']}%</td>
                        {/* <td><a className="landing-table-btn" href="#">Coin Info</a></td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="countery-sec" style={{ backgroundImage: `url(${require("../../static/images/background/2.jpg")})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="title-area">
                  <h4>Countries</h4>
                  <h3>100,000 Registered Users and Counting</h3>
                  <div className="text">Change Your Virtual Location at any time. Switch between <br /> countries in second.</div>
                </div>
                <div className="flag-area">
                  <ul className="flag-list">
                    <li><img src={require("../../static/images/icons/flag-1.jpg")} alt="" />Australia</li>
                    <li><img src={require("../../static/images/icons/flag-2.jpg")} alt="" />Austria</li>
                    <li><img src={require("../../static/images/icons/flag-3.jpg")} alt="" />belgium</li>
                    <li><img src={require("../../static/images/icons/flag-4.jpg")} alt="" />bulgaria</li>
                    <li><img src={require("../../static/images/icons/flag-5.jpg")} alt="" />czech-republic</li>
                    <li><img src={require("../../static/images/icons/flag-6.jpg")} alt="" />denmark</li>
                    <li><img src={require("../../static/images/icons/flag-7.jpg")} alt="" />france</li>
                    <li><img src={require("../../static/images/icons/flag-8.jpg")} alt="" />germany</li>
                    <li><img src={require("../../static/images/icons/flag-9.jpg")} alt="" />hungary</li>
                  </ul>

                  <ul className="flag-list">
                    <li><img src={require("../../static/images/icons/flag-10.jpg")} alt="" />italy</li>
                    <li><img src={require("../../static/images/icons/flag-11.jpg")} alt="" />japan</li>
                    <li><img src={require("../../static/images/icons/flag-12.jpg")} alt="" />luxembourg</li>
                    <li><img src={require("../../static/images/icons/flag-13.jpg")} alt="" />netherlands</li>
                    <li><img src={require("../../static/images/icons/flag-14.jpg")} alt="" />norway</li>
                    <li><img src={require("../../static/images/icons/flag-15.jpg")} alt="" />panama</li>
                    <li><img src={require("../../static/images/icons/flag-16.jpg")} alt="" />peru</li>
                    <li><img src={require("../../static/images/icons/flag-17.jpg")} alt="" />poland</li>
                    <li><img src={require("../../static/images/icons/flag-18.jpg")} alt="" />serbia</li>
                  </ul>

                  <ul className="flag-list">
                    <li><img src={require("../../static/images/icons/flag-19.jpg")} alt="" />singapore</li>
                    <li><img src={require("../../static/images/icons/flag-20.jpg")} alt="" />slovakia</li>
                    <li><img src={require("../../static/images/icons/flag-21.jpg")} alt="" />spain</li>
                    <li><img src={require("../../static/images/icons/flag-22.jpg")} alt="" />sweden</li>
                    <li><img src={require("../../static/images/icons/flag-23.jpg")} alt="" />switzerland</li>
                    <li><img src={require("../../static/images/icons/flag-24.jpg")} alt="" />united-kingdom</li>
                    <li><img src={require("../../static/images/icons/flag-25.jpg")} alt="" />united-states</li>
                    <li><img src={require("../../static/images/icons/flag-26.jpg")} alt="" />uruguay</li>
                    <li><img src={require("../../static/images/icons/flag-27.jpg")} alt="" />morocco</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='about' className="monyback-sec" style={{ backgroundImage: `url(${require("../../static/images/background/3.png")})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="title-area">
                  <h3>Get in touch. Stay in touch.</h3>
                </div>
              </div>
              <div className="box-column col-lg-8 col-md-12 col-sm-12">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="monyback-box">
                      <div className="icon-area">
                        <figure><img src={require("../../static/images/icons/1-landing.png")} alt="" /></figure>
                      </div>
                      <div className="text-area">
                        <h4>24 / 7 Support</h4>
                        <div className="text">Got a problem? Just get in touch. Our support team is available 24/7.</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="monyback-box">
                      <div className="icon-area">
                        <figure><img src={require("../../static/images/icons/3-landing.png")} alt="" /></figure>
                      </div>
                      <div className="text-area">
                        <h4>Community</h4>
                        <div className="text">Bit Dandy is global. Join the discussion in our worldwide communities.</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="monyback-box">
                      <div className="icon-area">
                        <figure><img src={require("../../static/images/icons/4-landing.png")} alt="" /></figure>
                      </div>
                      <div className="text-area">
                        <h4>Careers</h4>
                        <div className="text">Help build the future of technology. Start your new career at Bit Dandy.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="main-footer">
          <div className="footer-area">
          
          </div>
          <div className='footer-link-area mt-5'>
           
          </div>

          <div className="container">
            <div className="widgets-section">
              <div className="row clearfix">
                <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                  <div className="footer-widget about-widget">
                    <div className="footer-logo"><a href="index.html"><img src={require("../../static/images/logo.png")} alt="" /></a></div>
                    <div className="widget-content">
                      <div className="text">Softtik technologies provides customised blockchain solutions to all the clients worldwide for a better lifestyle.</div>
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-2 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h5 className="widget-title">Company</h5>
                    <div className="widget-content">
                      <ul className="list">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Trade Currency </a></li>
                        <li><a href="#">Career </a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Currency Calculator</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4 className="widget-title">Support</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li><a href="#">Contact Us</a></li>
                        <li><a href="#">FAQ </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-2 col-md-2 col-sm-12">
                   <div className="footer-widget links-widget">
                    <h2 className="widget-title">CONTACT US</h2>
                    <div className="widget-content">
                      <ul className="list-address">
                      <li className="tel"><i className="fa fa-phone"></i><a href="tel:+923170000067"> +923170000067</a></li>
                      <li className="email"><i className="fa fa-envelope"></i><a href="mailto:info@softtik.com"> info@softtik.com</a></li>
                      </ul>
                    </div>
                  </div> 
                </div>
              </div>
            </div> 
          </div>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = ({ Auth }) => {
  let { auth, landingBannerData, landingTableData } = Auth;
  return { auth, landingBannerData, landingTableData };
};
const mapDispatchToProps = { verifyWithdrawal };

export default connect(mapStateToProps, mapDispatchToProps)(Landing);