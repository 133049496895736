import { connect } from 'react-redux';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiPhoneInput from 'material-ui-phone-number';

import './index.css';
import Navbar from '../../components/Navbar';
import { sendSMS, smsKey, } from '../../store/actions/Auth';


class SmsAuthentication extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            smsKey: '',
        };
    }

    handleSendSMS = () => this.props.sendSMS({ phone: this.state.phone });

    handleSMSKey = () => this.props.smsKey({ smsKey: this.state.smsKey, history: this.props.history });

    render() {
        let { lightMode } = this.props;
        let { phone, smsKey } = this.state;

        return (
            <div className={`page-wrapper-inner ${lightMode ? 'light' : 'dark'}`}>
                <Navbar {...this.props} />

                <section className="sms-anthtication">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                                <div className="sms-anthtication-form">
                                    <div className="dash-top-title">
                                        <h2>Enable SMS Authentications</h2>
                                    </div>

                                    <form className="form-area">
                                        <div className="row">
                                            <div className="froum-group col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-item">
                                                    <MuiPhoneInput
                                                        fullWidth
                                                        name="phone"
                                                        value={phone}
                                                        margin="normal"
                                                        variant="outlined"
                                                        autoFormat={false}
                                                        defaultCountry='lu'
                                                        id='standard-full-width'
                                                        className='sms-input-field'
                                                        onChange={(phone) => this.setState({ phone })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="froum-group col-lg-8 col-md-12 col-sm-12">
                                                <TextField
                                                    fullWidth
                                                    name="smsKey"
                                                    type='number'
                                                    value={smsKey}
                                                    variant="outlined"
                                                    id='standard-full-width'
                                                    className='sms-input-field'
                                                    placeholder="SMS Authentication Code"
                                                    onChange={(e) => this.setState({ smsKey: e.target.value })}
                                                />
                                            </div>
                                            <div className="froum-group col-lg-4 col-md-12 col-sm-12">
                                                <button type="button" className="theme-btn btn-style-four auth-btn" onClick={this.handleSendSMS}>Get Code</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <div className="bottom-btn text-center">
                                    <button type='button' className="theme-btn btn-style-four submit-auth-btn" onClick={this.handleSMSKey}>SEND SMS</button>
                                </div>
                                <div className="notice-bottom">
                                    {/* <p><span><img src="images/icons/19.png" alt="" /></span>   Do not disclose Password, Google Authentication and SMS to anyone, including STABLE support.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

const mapDispatchToProps = { sendSMS, smsKey };
const mapStateToProps = ({ Auth, Trading }) => {
    let { lightMode } = Auth;
    let { } = Trading;
    return { lightMode };
}
export default connect(mapStateToProps, mapDispatchToProps)(SmsAuthentication);