import { connect } from 'react-redux';
import React, { Component } from 'react';

import NumberFormat from '../../components/NumberFormat';

class Orders extends Component {

  render() {
    let { buyOrders, sellOrders, tradeHistory, coinStats, selectedCoin } = this.props;
    return (
      <>
        {/**************  Buy && Sell Orders of Pair  **************/}

        <div className="group-price-bock">
          {/* <ul className="grap-btn">
            <li><img src={require("../../static/images/icons/7.png")} alt="Softtik Exchange" /></li>
            <li><img src={require("../../static/images/icons/7.png")} alt="Softtik Exchange" /></li>
            <li><img src={require("../../static/images/icons/7.png")} alt="Softtik Exchange" /></li>
          </ul> */}
          {/* <div className="group-btn">
            <p>Group</p>
            <select className="mdb-select md-form">
              <option value="1">2 decimals</option>
              <option value="2">2 decimals</option>
              <option value="3">2 decimals</option>
            </select>
          </div> */}
          <div className="table-area">
            <table className="table table-striped table-responsive-sm table-dark">
              <thead>
                <tr>
                  <th scope="col">Price ({selectedCoin.split('/')[1]})</th>
                  <th scope="col">Amount ({selectedCoin.split('/')[0]})</th>
                  <th scope="col">Total ({selectedCoin.split('/')[1]})</th>
                </tr>
              </thead>
              <tbody>
                {/**************  Sell Orders of Pair  **************/}
                {sellOrders.map(data => {
                  return (
                    <tr>
                      <td className="red">
                        <NumberFormat value={data['unitPrice']} coin={data['pair'].split('/')[1]}/>
                      </td>
                      <td>
                        <NumberFormat value={data['amount'] - (data['filledAmount'] || 0)}  coin={data['pair'].split('/')[1]}/>
                      </td>
                      <td>
                        <NumberFormat value={(data['amount'] - (data['filledAmount'] || 0)) * data['unitPrice']}  coin={data['pair'].split('/')[1]}/>
                      </td>
                    </tr>
                  )
                })}

                {coinStats['symbol'] &&
                  <tr className="active">
                    <th scope="row" className={coinStats['change'] >= 0 ? 'green' : 'red'}>
                      <NumberFormat value={coinStats['price']} coin={coinStats['symbol'].split('/')[1]}/>
                      <i className={coinStats['change'] >= 0 ? 'fa fa-long-arrow-alt-up' : 'fa fa-long-arrow-alt-down'}></i></th>
                    <td> <NumberFormat value={coinStats['price']} coin={coinStats['symbol'].split('/')[1]}/> </td>
                    <td className={coinStats['change'] >= 0 ? 'green' : 'red'}>More <i className="fa fa-signal"></i></td>
                  </tr>
                }

                {/**************  Buy Orders of Pair  **************/}
                {buyOrders.map(data => {
                  return (
                    <tr>
                      <td className="green">
                        <NumberFormat value={data['unitPrice']} coin={data['pair'].split('/')[1]}/>
                      </td>
                      <td>
                        <NumberFormat value={data['amount'] - (data['filledAmount'] || 0)} coin={data['pair'].split('/')[1]}/>
                      </td>
                      <td>
                        <NumberFormat value={(data['amount'] - (data['filledAmount'] || 0)) * data['unitPrice']} coin={data['pair'].split('/')[1]}/>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/**************  Trading History of Pair  **************/}

        <div className="group-price-bock style-two">
          <div className="table-area">
            <table className="table table-striped table-responsive-sm table-dark">
              <tbody>
                {tradeHistory.map(data => {
                  return (
                    <tr>
                      <td className={`${data['type'] == 'buy' ? 'green' : 'red'}`}>
                        <NumberFormat value={data['price']} coin={data['pair'].split('/')[1]}/>
                      </td>
                      <td>
                        <NumberFormat value={data['amount']} coin={data['pair'].split('/')[1]}/>
                      </td>
                      <td>
                        <NumberFormat value={data['price'] * data['amount']} coin={data['pair'].split('/')[1]}/>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = {  };

const mapStateToProps = ({ Trading }) => {
  let { buyOrders, sellOrders, tradeHistory, coinStats, selectedCoin } = Trading;
  return { buyOrders, sellOrders, tradeHistory, coinStats, selectedCoin };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);