import { connect } from 'react-redux';
import React, { Component } from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

import NumberFormat from '../../components/NumberFormat';

class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: 'DARK',
      selectedCoin: '',
    }
  }

  componentWillReceiveProps({ selectedCoin, lightMode, }) {
    this.setState({ theme: lightMode ? 'LIGHT' : 'DARK', selectedCoin: selectedCoin.replace('/', '') });
  }

  render() {
    let { coinStats } = this.props;
    let altCoin = '', masterCoin = '';
    if (coinStats['symbol']) {
      altCoin = coinStats['symbol'].split('/')[0];
      masterCoin = coinStats['symbol'].split('/')[1];
    }
    return (
      <>
        {coinStats['price'] &&
          <div className="top-area">
            <ul className="coin-blance-area">
              <li className="coin-img-area"><img class="coin-img mr-2" src={require(`../../static/images/icons/${altCoin}.png`)} alt={`Softtik Exchange ${altCoin}`} /> {coinStats['name']} ({`${altCoin}`})</li>
              <li>Price <br /><NumberFormat value={coinStats['price']} coin={masterCoin}/></li>
              <li>Change <br /> <span className={coinStats['change'] >= 0 ? 'green' : 'red'}>{parseFloat(coinStats['change'] || 0).toFixed('2')} %</span></li>
              <li>24h Low <br /> <NumberFormat value={coinStats['low']} coin={masterCoin}/></li>
              <li>24h High <br /> <NumberFormat value={coinStats['high']} coin= {masterCoin}/></li>
              <li>24h Volume<br /> {parseFloat(coinStats['volume'] || 0).toFixed('2')} {`${masterCoin === 'EUR' ? altCoin : masterCoin}`}</li>
            </ul>
          </div>
        }
        <div className="graph-area">
          <TradingViewWidget symbol={`BINANCE:${this.state.selectedCoin}`} theme={Themes[this.state.theme]} locale='en' autosize height />
        </div>
      </>
    )
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ Auth, Trading }) => {
  let { lightMode } = Auth;
  let { selectedCoin, coinStats } = Trading;
  return { lightMode, selectedCoin, coinStats };
};

export default connect(mapStateToProps, mapDispatchToProps)(Graph);