import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import Switch from "react-switch";
import { logout } from '../../store/actions/Auth';
import { changeMode } from '../../store/actions/Trading';

import "./index.css";

function Header({ changeMode, lightMode, location, logout }) {
  // function Header({ changeMode, location, logout }) {
  let { pathname } = location;
  const changeModeFunc = (checked) => changeMode(checked);

  return (
    <header className="inner-header header-style-one">
      <div className="header-upper">
        <div className="inner-container">
          <div className="container-fluid clearfix">
            <div className="nav-inner">
              <div className="logo-outer">
                <div className="logo row mb-2">
                  <Link to='/'><img className="navbar-logo-img" src={require('../../static/images/logo.png')} alt="Softtik Exchange" /></Link>
                </div>
              </div>
              <div className="nav-outer clearfix">
                <nav className="inner-menu navbar-expand-md navbar-light">
                  <div className="navbar-header">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>
                  <div className="collapse navbar-collapse clearfix" id="navbarSupportedContent">
                    <ul className="navigation clearfix">
                      <li className={`${pathname.match('trading') && 'current'}`}> <Link to='/trading'>Trading</Link> </li>
                      <li className={`${pathname.match('wallet') && 'current'}`}><Link to='/wallet'>Wallet</Link></li>
                      {/* <li><a href="#">History</a></li>
                      <li><a href="#">Grouping</a></li> */}
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="right-area">
                <ul className="right-box">
                  <li>
                    <Switch
                      onChange={(e) => changeModeFunc(e)}
                      checked={lightMode}
                      onColor="#919191"
                      onHandleColor="#21212f"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={15}
                      width={34}
                      className="react-switch"
                      id="material-switch" />
                  </li>
                  {/* <li className="current dropdown"><span className="fa fa-sort-down"></span><a href="#">Language</a>
                      <ul>
                        <li><a href="#">English</a></li>
                        <li><a href="#">Urdu</a></li>
                        <li><a href="#">Arabic</a></li>
                      </ul>
                    </li> */}
                  <li className={`${pathname.match('myPage') && 'current'}`}><Link to='/myPage'>My Page</Link></li>
                  <li><a onClick={logout}>Logout</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = ({ Auth, Trading }) => {
  let { lightMode } = Auth;
  let { } = Trading;
  return { lightMode };
};
const mapDispatchToProps = { changeMode, logout };

export default connect(mapStateToProps, mapDispatchToProps)(Header);