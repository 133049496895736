import React, { Component } from 'react';
import { connect } from 'react-redux';

import "./index.css";

import Coins from './Coins.js';
import Graph from './Graph.js';
import Orders from './Orders.js';
import BuySell from './BuySell.js';
import History from './History.js';
import Navbar from '../../components/Navbar';

import { tradingSockets, changeCoin } from '../../store/actions/Trading';

class Trading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCoin: props.selectedCoin
    };
    props.changeCoin('ETH/BTC');
    props.tradingSockets();
  }

  componentWillReceiveProps({ selectedCoin }) {
    console.log(`*******selectedCoin update = `, selectedCoin);
    if (selectedCoin && !this.state.selectedCoin)
      this.props.tradingSockets(selectedCoin);
    this.setState({ selectedCoin });
  }

  render() {
    let { lightMode } = this.props;
    return (
      <>
        <div className={`page-wrapper-inner ${lightMode ? 'light' : 'dark'}`}>
          <Navbar {...this.props} />
          <section className="admin-area">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 left-sidebar">
                  <Coins />
                </div>
                <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                  <div className="right-side-bar">
                    <div className="row clearfix">
                      <div className="graph-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                        <div className="graph-sec">
                          <Graph />
                        </div>
                        <div className="history-sec">
                          <History />
                        </div>
                      </div>
                      <div className="table-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                        <div className="orders-sec">
                          <Orders />
                        </div>
                        <div className="form-sec-buy">
                          <BuySell />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { tradingSockets, changeCoin };

const mapStateToProps = ({ Auth, Trading }) => {
  let { lightMode } = Auth;
  let { selectedCoin, data } = Trading;
  return { lightMode, selectedCoin, data };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trading);