import React, { Component } from 'react';
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from './Login';
import MyPage from './MyPage';
import Forget from './Forget';
import Wallet from './Wallet';
import Landing from './Landing';
import Trading from './Trading';
import ResetPass from './ResetPass';
import APIKeys from './ApiKeys/index';
import Refferal from './Referral/index';
import Whitelisting from './Whitelisting/index';
import PrivateRoute from "../store/PrivateRoute";
import SignupBasic from './Signup/SignupBasic.js';
import SmsAuthentication from './SmsAuthentication';
import SignupAdvance from './Signup/SignupAdvance.js';
import GoogleAuthentication from './GoogleAuthentication';


import { logout } from '../store/actions/Auth';
import { changeCoin } from '../store/actions/Trading';

import '../static/css/style.css';
import '../static/css/responsive.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const hist = createBrowserHistory();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    props.changeCoin('ETH/BTC');
  }

  componentDidMount() {
    EventBus.on('tokenExpired', () => this.props.logout());
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  }

  render() {
    return (
      <div>
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />
        <Router history={hist}>
          <Switch>
            <Route exact path='/forget' component={props => <Forget {...props} />} />
            <Route exact path='/' component={props => <Landing {...props} />} />
            <Route exact path='/signup' component={props => <SignupBasic {...props} />} />
            <Route exact path='/continueSignup/:id' component={props => <SignupAdvance {...props} />} />
            <Route exact path='/reset_password/:id' component={props => <ResetPass {...props} />} />
            <Route exact path='/verify_withdrawal/:id' component={props => <Landing {...props} />} />

            <Route exact path='/login' component={props => <Login {...props} />} />
            <PrivateRoute path='/myPage' component={props => <MyPage {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/trading' component={props => <Trading {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/wallet' component={props => <Wallet {...props} />} /> {/* Theme Here */}

            {/* <Route path='/allKeys' component={props => <AllKeys {...props} />} /> Theme Here
            <Route path='/newKey' component={props => <NewKey {...props} />} /> Theme Here */}

            <Route path='/apiKeys' component={props => <APIKeys {...props} />} /> {/* Theme Here */}

            <PrivateRoute path='/referral' component={props => <Refferal {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/whiteListing' component={props => <Whitelisting {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/smsAuthentication' component={props => <SmsAuthentication {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/googleAuthentication' component={props => <GoogleAuthentication {...props} />} /> {/* Theme Here */}
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapDispatchToProps = { changeCoin, logout };
const mapStateToProps = ({ Trading, Auth }) => {
  let { auth } = Auth;
  return { auth };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);