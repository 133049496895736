import { connect } from 'react-redux';
import React, { Component } from 'react';

import AppLoader from '../../components/AppLoader';
import { setCoin } from '../../store/actions/Wallet';
import NumberFormat from '../../components/NumberFormat';
import { getWalletBalance } from '../../store/actions/Wallet';

class Balance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filterCoin: '',
      showWallets: [],
      balanceLoader: true,
    };
    props.getWalletBalance();
  }

  componentWillReceiveProps({ wallets }) {
    if (!this.state.filterCoin) this.setState({ showWallets: wallets });
    else this.setState({ showWallets: wallets.filter(({ name }) => name.toLowerCase().indexOf(this.state.filterCoin) >= 0) })
  }

  openDeposit = (symbol) => {
    this.props.history.push('/wallet/deposit');
    this.props.setCoin(symbol);
  }

  openWithdraw = (symbol) => {
    this.props.history.push('/wallet/withdraw');
    this.props.setCoin(symbol);
  }

  filterCoin = (e) => {
    this.setState({
      filterCoin: e.target.value,
      showWallets: this.props.wallets.filter(({ name }) => name.toLowerCase().indexOf(e.target.value) >= 0)
    })
  }

  render() {
    let { showWallets } = this.state;
    let { wallets, balanceLoader } = this.props;
    return (
      <>
        <div class="deposit-inner">
          <div class="search-area">
            <form class="checkbox-form">
              <div class="froum-group">
                {/* <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                <label class="form-check-label" for="exampleCheck2">Hide small balance</label> */}
              </div>
            </form>
            <form class="search-form">
              <div class="froum-group">
                <input type="text" name="" placeholder="Search Here" class="control-form" onChange={this.filterCoin} />
                <button><i class="fa fa-search"></i></button>
              </div>
            </form>
          </div>
          <div class="balance-table">
            <div class="table-responsive">
              <table class="table table-clr table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col"><span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span> Coin Name </th>
                    <th scope="col"><span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span> Total Balance </th>
                    <th scope="col"><span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span> Available </th>
                    <th scope="col"><span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span> In Order </th>
                    <th scope="col"><span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span> BTC Value </th>
                    <th scope="col"><span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span> Fiat Value USDT </th>
                    <th scope="col"><span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span> Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {balanceLoader
                    ? <tr>
                      <td colSpan={7}>
                        <AppLoader message={"Loading your wallet balance..."} />
                      </td>
                    </tr>
                    :
                    <>
                      {showWallets.map(data => {
                        return (
                          <tr>
                            <td className='text-left'><span class="group-icon"><img className='balance-table-img' src={data['icon']} alt="" /></span> {data['name']}</td>
                            <td><NumberFormat value={data['totalBalance']} coin={data['symbol']} /></td>
                            <td><NumberFormat value={data['confirmedBalance']} coin={data['symbol']} /></td>
                            <td><NumberFormat value={data['onholdBalance']} coin={data['symbol']} /></td>
                            <td><NumberFormat value={data['btcValue']} coin={'BTC'} /></td>
                            <td><NumberFormat value={data['fiatValue']} coin={'USDT'} /></td>
                            <td>
                              <a href="#" onClick={() => this.openDeposit(data['symbol'])}>Deposit</a>
                              <a href="#" onClick={() => this.openWithdraw(data['symbol'])}>Withdrawal</a>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { setCoin, getWalletBalance };

const mapStateToProps = ({ Wallet }) => {
  let { wallets, balanceLoader } = Wallet;
  return { wallets, balanceLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(Balance);