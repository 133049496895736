import moment from 'moment';
import QRCode from "react-qr-code";
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import AppLoader from '../../../components/AppLoader';
import NumberFormat from '../../../components/NumberFormat';
import { getCoinWallet, getDepositHistory, setCoin } from '../../../store/actions/Wallet';

class Crypto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: true,
      walletList: [],
      type: 'Deposit',
      condition: {
        0: 'Pending',
        1: 'Completed',
        2: 'Failed',
        3: 'Cancelled',
      },
      selectedWallet: props.selectedWallet,
    };
    props.getCoinWallet(props.selectedWallet);
    props.getDepositHistory({ type: this.state.type, symbol: props.selectedWallet });
  }

  componentWillReceiveProps({ selectedWallet, wallets }) {
    if (selectedWallet !== this.state.selectedWallet) {
      this.setState({ selectedWallet });
      this.props.getCoinWallet(selectedWallet);
      this.props.getDepositHistory({ type: this.state.type, symbol: selectedWallet })
    }
    this.setState({ walletList: wallets.filter(({ symbol }) => symbol !== selectedWallet) });
  }

  copied = () => EventBus.publish("success", 'Wallet Address Copied');

  render() {
    let { condition, walletList } = this.state;
    let { coinWallet, depositHistory, coinLoader, depositLoader } = this.props;
    let coinData = coinWallet['coinId'] || {};
    return (
      <>
        <div class="row clearfix">
          <div class="col-lg-6 col-md-12">
            {coinLoader
              ? <div className='pt-5 mt-5'><AppLoader message={`Generating your wallet address`} /></div> :
              <div class="qr-sec">
                <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                  <div class="card">
                    <h6 className='text-white my-2 ml-3'>
                      Balance:  <NumberFormat value={coinWallet['confirmedBalance']} coin={coinWallet['symbol']} /> {coinWallet['symbol']}
                    </h6>
                    <div class="card-header" role="tab" id="headingOne1">
                      <div class="btn-group">
                        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span class="icon-left">
                            <img src={coinData['icon']} className="dropdown-icon" alt="Bitcoin Logo" />
                          </span>
                          {coinData['coinName']} ({coinData['symbol']})
                        </button>
                        <div class="dropdown-menu">
                          {walletList.map(wallet => (
                            <button class="dropdown-item" type="button" onClick={() => this.props.setCoin(wallet['symbol'])}>
                              <span class="icon-left">
                                <img src={wallet['icon']} className='dropdown-icon' alt="Bitcoin Logo" />
                              </span>
                              {wallet['name']} ({wallet['symbol']})
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="top-copy-area">
                        <h4>Deposit Address:</h4>
                        <div class="copyext">{coinWallet['address']} </div>
                        {coinWallet['address'] && <CopyToClipboard text={coinWallet['address']} onCopy={this.copied}>
                          <button class="btn">Copy</button>
                        </CopyToClipboard>}
                      </div>
                      <div class="qr-reading">
                        {coinWallet['address'] && <QRCode value={coinWallet['address']} size='200' />}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bottom-note mt-4">
                  <h4>
                    Use only {coinData['coinName']} deposit address. <br />
                      Coins will be deposited after 3 network confirmations
                    </h4>
                </div>
              </div>
            }
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="history-deposit">
              <h5>History</h5>
              <div class="table-responsive-md">
                <table class="table table-clr text-center">
                  <thead>
                    <tr>
                      <th scope="col">Time <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Coin <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Amount <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Status <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                      <th scope="col">Tx ID <span class="icn-box"><i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositLoader ?
                      <tr>
                        <td colSpan={7}>
                          <AppLoader message={"Loading deposit history"} />
                        </td>
                      </tr>
                      :
                      <Fragment>
                        {depositHistory.map(data => {
                          return (
                            <tr>
                              <td>{moment(data['createdAt']).format('MMM Do, h:mm a')}</td>
                              <td>{data['symbol']}</td>
                              <td><NumberFormat value={data['value']} coin={data['symbol']} /></td>
                              <td>{condition[data['status']]}</td>
                              <td>{(data['status'] === 1) ? <a href={data['coinId']['testExplorer'] + data['transactionHash']} target='_blank' className="wallet-table-btn">View</a> : '--'}</td>
                            </tr>
                          )
                        })}
                      </Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { getCoinWallet, getDepositHistory, setCoin };

const mapStateToProps = ({ Wallet }) => {
  let { selectedWallet, coinWallet, depositHistory, wallets, coinLoader, depositLoader } = Wallet;
  return { selectedWallet, coinWallet, depositHistory, wallets, coinLoader, depositLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(Crypto);