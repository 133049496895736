export const signUpBasic = ({ data }) => ({
  type: 'SIGNUP_BASIC',
  payload: data,
});

export const signupAdvance = ({ data, history }) => ({
  type: 'SIGNUP_ADVANCE',
  payload: data,
  history
});

export const verifyEmail = ({ data, history }) => ({
  type: 'VERIFY_EMAIL',
  payload: data,
  history
});

export const login = ({ data, history }) => ({
  type: 'LOGIN',
  payload: data,
  history
});

export const userData = (data) => ({
  type: 'USER_DATA',
  payload: data
});

export const userAuth = (data) => ({
  type: 'USER_AUTH',
  payload: data,
});

export const getKYCData = () => ({
  type: 'GET_KYC_DATA',
});

export const setKYCData = (data) => ({
  type: 'SET_KYC_DATA',
  payload: data,
});

export const load = (data) => ({
  type: 'LOADING',
  payload: data
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const forgotPassword = (data) => ({
  type: 'FORGOT_PASSWORD',
  payload: data
});

export const resetPassword = ({ data, history }) => ({
  type: 'RESET_PASSWORD',
  payload: data,
  history
});

export const changePassword = (data) => ({
  type: 'CHANGE_PASSWORD',
  payload: data
});

export const landingPageData = (data) => ({
  type: 'LANDING_PAGE_DATA',
  payload: data
});

export const loginSms = (data) => ({
  type: 'LOGIN_SMS',
  payload: data
});

export const verifySmsLogin = ({ data, id, history }) => ({
  type: 'VERIFY_SMS_LOGIN',
  payload: data,
  history,
  id
});

export const loginVerifyGoogle = ({ data, id, history }) => ({
  type: 'VERIFY_GOOGLE_LOGIN',
  payload: data,
  history,
  id
});

export const enableGoogleAuth = () => ({
  type: 'ENABLE_GOOGLE_AUTH'
});

export const googleAuthSettings = (data) => ({
  type: 'GOOGLE_AUTH_SETTINGS',
  payload: data
});

export const googleAuthVerify = ({ data, history }) => ({
  type: 'GOOGLE_AUTH_VERIFY',
  payload: data,
  history
});

export const disableGoogle = () => ({
  type: 'DISABLE_GOOGLE'
});

export const toggleModalPhishing = () => ({
  type: 'TOGGLE_MODAL_PHISHING'
});

export const setAntiPhishing = (data) => ({
  type: 'SET_ANTI_PHISHING',
  payload: data,
});

export const myPage = () => ({
  type: 'MY_PAGE',
});

export const myPageData = (data) => ({
  type: 'MY_PAGE_DATA',
  payload: data
});

export const sendSMS = (data) => ({
  type: 'SEND_SMS',
  payload: data
});

export const smsKey = (data, history) => ({
  type: 'SMS_KEY',
  payload: data,
  history
});

export const disableSMS = () => ({
  type: 'DISABLE_SMS',
});

export const myPageUserData = (data) => ({
  type: 'MY_PAGE_USER_DATA',
  payload: data
});

export const toggleAuthModal = () => ({
  type: 'TOGGLE_AUTH_MODAL',
});

export const toggleKYCModal = () => ({
  type: 'TOGGLE_KYC_MODAL',
});

export const verifyKYC = (data) => ({
  type: 'VERIFY_KYC',
  payload: data
});

export const APIKeysLoader = (data) => ({
  type: 'API_KEYS_LOADER',
  payload: data
});

export const getAPIKeys = () => ({
  type: 'GET_API_KEYS',
});

export const setAPIKeys = (data) => ({
  type: 'SET_API_KEYS',
  payload: data,
});

export const createAPIKey = ({ data }) => ({
  type: 'CREATE_API_KEY',
  payload: data,
});

export const deleteAllAPIKey = () => ({
  type: 'DELETE_API_ALL_KEY',
});

export const deleteAPIKey = ({ data }) => ({
  type: 'DELETE_API_KEY',
  payload: data,
});

export const editApiKey = (data) => ({
  type: 'EDIT_API_KEY',
  payload: data,
});

export const getReferrals = () => ({
  type: 'GET_REFFERRALS',
});

export const setReferrals = (data) => ({
  type: 'SET_REFFERRALS',
  payload: data,
});

export const getWhitelistedAddress = () => ({
  type: 'GET_WHITELISTED_ADDRESS',
});

export const setWhitelistedAddress = (data) => ({
  type: 'SET_WHITELISTED_ADDRESS',
  payload: data,
});

export const addWhitelistAddress = (data) => ({
  type: 'ADD_WHITELIST_ADDRESS',
  payload: data,
});

export const whitelistAdded = (data) => ({
  type: 'WHITELIST_ADDED',
  payload: data,
});

export const deleteAddress = (data) => ({
  type: 'DELETE_ADDRESS',
  payload: data,
});

export const deleteMultiAddresses = (data) => ({
  type: 'DELETE_MULTI_ADDRESSES',
  payload: data,
});

export const toggleWhitelist = (data) => ({
  type: 'TOGGLE_WHITELIST',
  payload: data,
});
