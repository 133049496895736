import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import { Fragment } from 'react';
import NumberFormat from '../../components/NumberFormat';
import { cancelOrder } from '../../store/actions/Trading';

class History extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orderTable: true
    };
  }

  renderOpenOrders = () => {
    let { openOrders, selectedCoin } = this.props;
    return (
      <table className="table table-striped  table-fixed table-responsive-sm table-dark">
        <thead className="date-sec">
          <tr>
            {/* <th scope="col">Date</th> */}
            <th scope="col">Pair</th>
            <th scope="col">Side</th>
            <th scope="col">Price ({selectedCoin.split('/')[1]})</th>
            <th scope="col">Amount ({selectedCoin.split('/')[0]})</th>
            <th scope="col">Filled</th>
            <th scope="col">Total ({selectedCoin.split('/')[1]})</th>
            <th scope="col">Cancel</th>
          </tr>
        </thead>
        <tbody>
          {/**************  Open Orders of User  **************/}
          {openOrders.map(data => {
            return (
              <tr>
                {/* <td>{moment(data['time']).format('lll')}</td> */}
                <td>{data['pair']}</td>
                <td style={{ textTransform: 'capitalize' }} className={data['action'] == 'buy' ? 'green' : 'red'}>
                  {data['action']}
                </td>
                <td><NumberFormat value={data['price']}/></td>
                <td><NumberFormat value={data['amount']} /></td>
                <td>{data['filledPercent']} %</td>
                <td><NumberFormat value={data['total']} /></td>
                <td className="cancel-icon" onClick={()=> this.props.cancelOrder(data['_id'])}><i className="fa fa-times" /></td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderOrderHistory = () => {
    let { orderHistory } = this.props;
    console.log(`**********orderHistory`, orderHistory);
    return (
      <table className="table table-striped table-fixed table-responsive-sm table-dark">
        <thead className="date-sec">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Pair</th>
            <th scope="col">Side</th>
            <th scope="col">Price</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          {/**************  History of User  **************/}
          {orderHistory.map(data => {
            return (
              <tr>
                <td>{moment(data['createdAt']).format('lll')}</td>
                <td>{data['pair']}</td>
                <td>{data['action'] === 'buy' ? 'Buy' : 'Sell'}</td>
                <td><NumberFormat value={data['unitPrice']} /></td>
                <td><NumberFormat value={data['amount']} /></td>
                <td style={{textTransform: 'capitalize'}}>{data['status']}</td>
                <td><NumberFormat value={data['amount'] * data['unitPrice']} /></td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  render() {
    let { orderTable } = this.state;
    let { openOrders } = this.props;
    return (
      <>
        <ul className="orderlist">
          <li className={orderTable ? "active mr-2" : "mr-2"}><a href="#" type='button' onClick={() => this.setState({ orderTable: true })}>Open orders <span> ({openOrders.length})</span></a></li>
          <li className={!orderTable ? "active mr-2" : "mr-2"}><a href="#" type='button' onClick={() => this.setState({ orderTable: false })}>Order history</a></li>
        </ul>

        <div className="order-detail">
          <div className="inner-col">
            <div className="table-area">
              {/* <p>You have no open orders.</p> */}
              {orderTable ? this.renderOpenOrders() : this.renderOrderHistory()}
            </div>
          </div>
        </div>
      </>
    )
  }
}


const mapDispatchToProps = {cancelOrder};

const mapStateToProps = ({ Trading }) => {
  let { openOrders, orderHistory, selectedCoin } = Trading;
  return { openOrders, orderHistory, selectedCoin };
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
